export const isInSharePointIframe = (): boolean => {
  try {
    // Check if the page is in an iframe
    if (window.self !== window.top) {
      // Check if the referrer is from SharePoint
      const referrer = document.referrer;
      const isSharePointReferrer = referrer.startsWith('https://taylorsds.sharepoint.com/');

      // Check if the current origin is the expected Taylors Delivered domain
      const currentOrigin = window.location.origin;
      const isTaylorsDeliveredOrigin = currentOrigin === 'https://delivered.taylorsds.com.au';

      // Return true if both conditions are met
      return isSharePointReferrer && isTaylorsDeliveredOrigin;
    }
  } catch (error) {
    console.error('Error checking SharePoint iframe:', error);
  }
  
  return false;
};

export const isLocalhost = (): boolean => {
  const hostname = window.location.hostname;
  return hostname === 'localhost' || hostname === '127.0.0.1';
};

export const handleAuthorization = (): void => {
  if (isInSharePointIframe()) {
    // Continue with the application
    console.log('Authorized: Request has been authorised');
  } else {
    // Show unauthorized message or redirect
    console.error('Unauthorized: This resource requires an authorised request. Please speak to the Digital team.');
    // You might want to redirect to an error page or show a message in the UI
    // For now, we'll just throw an error
    throw new Error('Unauthorized: This resource requires an authorised request. Please speak to the Digital team.');
  }
};
